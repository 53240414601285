import React from "react";
import { StaticQuery, graphql } from "gatsby";

import styled from "styled-components";
import Wrapper from "src/components/global/Wrapper.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import Fb from "src/assets/svg/fb-white.svg";
import BodyText from "src/components/typography/BodyText.js";

import typographySizes from "src/assets/styles/typographySizes";

const FooterBG = styled.div`
  padding: 100px 0;
  background-color: ${colors.lightGrey};
  width: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 40px 0 60px 0;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding: 6.933vw 0;
  }
`;

const FooterWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const FooterTitle = styled.h4`
  font-size: 10px;
  color: ${colors.mainColor};
  font-weight: 300;
  margin-bottom: 10px;
  @media (min-width: ${mediaQuery.max}) {
    font-size: 0.69vw;
    line-height: 1.33;

    margin-bottom: 0.69vw;
  }
`;

const StyledFb = styled(Fb)`
  path {
    fill: ${colors.darkGrey};
  }
  @media (min-width: ${mediaQuery.max}) {
    width: 0.682vw;
    height: 1.308vw;
  }
`;

const FacebookWrapper = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    border-bottom: solid 1px ${colors.white};
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`;

const AdressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 66.66%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 83.33%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    flex-direction: column;
  }
`;

const AdressCol = styled.div`
  align-self: flex-end;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    align-self: flex-start;
    &.midcol {
      margin-bottom: 45px;
      margin-top: 20px;
    }
  }
`;

const Link = styled.a`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  display: block;
  transition: all 0.5s;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;
  }
  :hover {
    transition: all 0.5s;
    color: ${colors.mainColor};
  }
`;

const FooterComponent = ({ query }) => (
  <FooterBG>
    <FooterWrapper>
      <FacebookWrapper>
        <FooterTitle>Znajdź nas</FooterTitle>
        <a target="blank" href={query.strapiSocialMedia.Link}>
          <StyledFb />
        </a>
        {console.log(query.strapiSocialMedia.Link)}
      </FacebookWrapper>
      <AdressWrapper>
        <AdressCol>
          <FooterTitle>Adres</FooterTitle>

          <BodyText>{query.strapiContactInformation.Adress_1}</BodyText>
          <BodyText>{query.strapiContactInformation.Adress_3}</BodyText>
        </AdressCol>
        <AdressCol className="midcol">
          <BodyText>{query.strapiContactInformation.Adress_2}</BodyText>
          <BodyText>{query.strapiContactInformation.Adress_4}</BodyText>
        </AdressCol>
        <AdressCol>
          <FooterTitle>Kontakt</FooterTitle>

          <Link href={`tel:${query.strapiContactInformation.Telephone}`}>
            {query.strapiContactInformation.Telephone}
          </Link>
          <Link href={`mailto:${query.strapiContactInformation.Mail}`}>
            {query.strapiContactInformation.Mail}
          </Link>
        </AdressCol>
      </AdressWrapper>
    </FooterWrapper>
  </FooterBG>
);

export default function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          strapiContactInformation {
            Mail
            Telephone
            Adress_1
            Adress_3
            Adress_2
            Adress_4
          }
          strapiSocialMedia {
            Link
          }
        }
      `}
      render={(data) => <FooterComponent query={data} />}
    />
  );
}
