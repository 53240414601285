import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { Link } from "gatsby";
import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";
import Wrapper from "src/components/global/Wrapper.js";

import Logo from "src/assets/svg/vega_Logo.svg";
import LogoWhite from "src/assets/svg/vega-Logo_white.svg";

import Fb from "src/assets/svg/fb-white.svg";

const NavigationWrapper = styled(Wrapper)`
  display: flex;
  position: fixed;
  margin: 0;
  padding: 0 calc(50% - (50% - 140px));
  height: 100px;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  background-color: ${colors.white};
  @media (max-width: ${mediaQuery.desktop}) {
    padding: 0 ${sizes.bigMargin}px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0 ${sizes.smallMargin}px;
    height: 75px;
  }
  @media (min-width: ${mediaQuery.max}) {
    height: 6.933vw;
    padding: 0 calc(50% - (50% - 9.7vw));
  }
`;

const NavListWrapper = styled.div`
  width: 50%;

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 0;
    background-color: ${colors.mainColor};
    align-items: flex-end;
    justify-content: flex-end;
    max-height: 0px;
    transition: all 0.5s;
    &.open {
      max-height: 100%;
      transition: all 1.4s cubic-bezier(0.17, 0.67, 0.03, 1.33);
    }
  }
`;

const NavList = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    align-items: flex-end;

    width: calc(100% - ${sizes.smallMargin * 4}px);
    margin-right: ${sizes.smallMargin * 2}px;
    padding-bottom: ${sizes.smallMargin}px;
    border-bottom: solid 1px ${colors.white};
  }
`;
const NavigationLink = styled(Link)`
  text-decoration: none;
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  transition: color 0.5s;
  padding: 15px 0;
  font-weight: 300;
  &.active {
    color: ${colors.mainColor};
    transition: color 0.5s;
  }
  :hover {
    color: ${colors.mainColor};
    transition: color 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    color: ${colors.white};
    opacity: 0;
    position: relative;
    font-size: ${typographySizes.s * 2}px;

    &.close {
      left: -50vw;
      opacity: 0;
      transition: left 0s 0.5s, opacity 0.2s 0s;
    }

    &.open:nth-of-type(1) {
      transition: left 1s 0s, opacity 1s 0.3s;
      left: 0vw;
      opacity: 1;
    }
    &.open:nth-of-type(2) {
      transition: left 1s 0.1s, opacity 1s 0.4s;
      left: 0vw;
      opacity: 1;
    }
    &.open:nth-of-type(3) {
      transition: left 1s 0.2s, opacity 1s 0.5s;
      left: 0vw;
      opacity: 1;
    }
    &.open:nth-of-type(4) {
      transition: left 1s 0.3s, opacity 1s 0.6s;
      left: 0vw;
      opacity: 1;
    }
    &.active {
      color: ${colors.white};
      transition: color 0.5s;
      text-decoration: underline;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;

    padding: 1, 0399vw 0;
  }
`;

const FbLink = styled.a`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
    z-index: 999;
    padding: ${sizes.smallMargin * 2}px;
  }
`;

const StyledLogoLink = styled(Link)`
  /* height: 42px;
  width: 223.6px; */
  z-index: 999;
  display: flex;
  align-items: center;
  @media (min-width: ${mediaQuery.max}) {
    .Navigation__StyledLogo-kwaCFK {
      width: 15.5vw;
      height: 2.911vw;
    }
    /* width: 15.5vw;
    height: 2.911vw; */
  }
`;

const StyledLogo = styled(Logo)`
  @media (max-width: ${mediaQuery.tablet}) {
    position: absolute;
    /* width: 99.5px;
    height: 18.7px; */
    opacity: ${(props) => (props.open ? 0 : 1)};
    transition: opacity 0.5s;
    @media (max-width: ${mediaQuery.tablet}) {
      width: 150px;
    }
    @media (min-width: ${mediaQuery.max}) {
      width: 6.898vw;
      height: 1.296vw;
    }
  }
`;

const StyledLogoWhite = styled(LogoWhite)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;

    position: absolute;
    width: 150px;

    opacity: ${(props) => (props.open ? 1 : 0)};
    transition: opacity 0.5s;
  }
`;
const Hamburger = styled.button`
  height: 20px;
  width: 22.3px;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  display: none;
  z-index: 999;
  top: 30px;
  right: ${sizes.smallMargin}px;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inherit;
  }
`;

const TopBar = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.mainColor};
  top: 0;
  position: absolute;
  transition: all 0.5s;

  &.open {
    top: 6px;
    transition: all 0.5s;
    background-color: ${colors.white};
  }
`;

const MidBar = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.mainColor};
  top: 6px;
  position: absolute;
  transition: all 0.5s;

  &.open {
    transition: all 0.5s;
    background-color: ${colors.white};
  }
`;

const BottomBar = styled.div`
  height: 1px;
  width: 100%;
  position: absolute;
  transition: all 0.5s;
  top: 12px;
  background-color: ${colors.mainColor};
  &.open {
    top: 6px;
    transition: all 0.5s;
    background-color: ${colors.white};
  }
`;
class Navigation extends React.Component {
  state = {
    isClosed: false,
  };

  changeNavState = () => {
    this.setState({
      isClosed: this.state.isClosed ? false : true,
    });
  };
  changeNavStateLink = () => {
    this.setState({
      isClosed: false,
    });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query NavigationQuery {
            strapiSocialMedia {
              Link
            }
          }
        `}
        render={(data) => (
          <NavigationWrapper
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <StyledLogoLink onClick={this.changeNavStateLink} to="/">
              <StyledLogo open={this.state.isClosed} />
              <StyledLogoWhite open={this.state.isClosed} />
            </StyledLogoLink>
            <Hamburger onClick={this.changeNavState} aria-label="Menu">
              <TopBar className={this.state.isClosed ? "open" : "close"} />
              <MidBar className={this.state.isClosed ? "open" : "close"} />
              <BottomBar className={this.state.isClosed ? "open" : "close"} />
            </Hamburger>
            <NavListWrapper className={this.state.isClosed ? "open" : "close"}>
              <NavList>
                <NavigationLink
                  onClick={this.changeNavState}
                  className={this.state.isClosed ? "open" : "close"}
                  activeClassName="active"
                  partiallyActive={true}
                  to="/realizacje"
                >
                  Realizacje
                </NavigationLink>
                <NavigationLink
                  onClick={this.changeNavState}
                  className={this.state.isClosed ? "open" : "close"}
                  activeClassName="active"
                  partiallyActive={true}
                  to="/projektowanie"
                >
                  Projektowanie
                </NavigationLink>
                <NavigationLink
                  onClick={this.changeNavState}
                  className={this.state.isClosed ? "open" : "close"}
                  activeClassName="active"
                  partiallyActive={true}
                  to="/onas"
                >
                  O nas
                </NavigationLink>
                <NavigationLink
                  onClick={this.changeNavState}
                  className={this.state.isClosed ? "open" : "close"}
                  activeClassName="active"
                  partiallyActive={true}
                  to="/kontakt"
                >
                  Kontakt
                </NavigationLink>
              </NavList>
              <FbLink href={data.strapiSocialMedia.Link} target="blank">
                <Fb />
              </FbLink>
            </NavListWrapper>
          </NavigationWrapper>
        )}
      />
    );
  }
}

export default Navigation;
