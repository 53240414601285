// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-project-js": () => import("./../../../src/layouts/project.js" /* webpackChunkName: "component---src-layouts-project-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-projektowanie-js": () => import("./../../../src/pages/projektowanie.js" /* webpackChunkName: "component---src-pages-projektowanie-js" */),
  "component---src-pages-realizacje-js": () => import("./../../../src/pages/realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */)
}

