const typographySizes = {
  xs: 15,
  s: 15,
  maxS: 1.04,
  sm: 20,
  maxSm: 1.386,
  m: 30,
  maxM: 2.08,
  l: 55,
  maxL: 3.813,
  xl: 85,
  maxXl: 5.9,
};

export default typographySizes;
