import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";

const Wrapper = styled.div`
  width: calc(100% - ${sizes.xlMargin * 2}px);
  margin: 0 auto;
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(100% - ${sizes.bigMargin * 2}px);
    margin: 0 ${sizes.bigMargin}px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - ${sizes.smallMargin * 2}px);
    margin: 0 auto;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(100% - (9.7vw * 2));
  }
`;

export default Wrapper;
