import React from "react";
import { ContextProviderComponent } from "src/layouts/Context.js";

import Navigation from "src/components/global/Navigation.js";
import GlobalStyle from "src/assets/styles/globalStyles.js";
import Footer from "src/components/global/Footer.js";
import Cookies from "src/components/global/Cookies.js";

const IndexPage = ({ children }) => {
  //   if (typeof window !== "undefined") {
  //     // eslint-disable-next-line global-require
  //     require("smooth-scroll")('a[href*="#"]', {
  //       speed: 1000,
  //       offset: 100,
  //       speedAsDuration: true,
  //       easing: "easeInOutCubic",
  //     });
  //   }

  return (
    <>
      <Cookies />
      <ContextProviderComponent>
        <GlobalStyle />
        <Navigation />
        {children}
        <Footer />
      </ContextProviderComponent>
    </>
  );
};

export default IndexPage;
