import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const BodyText = styled.p`
  font-size: ${typographySizes.s}px;
  color: ${(props) => (props.color ? props.color : colors.darkGrey)};
  line-height: 1.33;
  font-weight: 300;
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
    line-height: 1.5;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;
  }
`;

export default BodyText;
