import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-family: "Montserrat", sans-serif !important;
        color: ${colors.darkGrey};
        -webkit-font-smoothing: antialiased;

    }
    ::selection {
        color: ${colors.white};
        background: ${colors.mainColor};
}

 body{
     overflow-x: hidden;
 }
 strong {
      background-color: ${colors.mainColor};
    }


    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
