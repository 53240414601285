const colors = {
  darkGrey: "#4b4b4b",
  lightGrey: "#f4f4f4",
  midGrey: "#bebebe",
  mainColor: "#3aa6e6",
  darkBlue: "#1477b2",
  deepBlue: "#004872",
  lightenMainColor: "#57c0ff",
  white: "#ffffff",
};

export default colors;
