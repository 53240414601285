import React from "react";
import { StaticQuery, graphql } from "gatsby";
import CookieConsent from "react-cookie-consent";
import PolitykaPrywatnosci from "src/assets/Politykaprywatnosci.pdf";

import colors from "src/assets/styles/colors.js";
const Cookies = () => (
  <CookieConsent
    style={{
      background: `${colors.mainColor}`,
      padding: "0px",
      color: `${colors.white}`,
    }}
    buttonStyle={{ background: `#fff`, margin: "5px 0px 5px 15px" }}
    declineButtonStyle={{
      background: `transparent`,
      margin: "5px 15px 5px 5px",
      color: `${colors.white}`,
      textDecoration: "underline",
    }}
    contentStyle={{ margin: "5px 15px" }}
    location="bottom"
    buttonText="Akceptuje"
    enableDeclineButton
    flipButtons
    declineButtonText="Nie akcpetuje"
    cookieName="gatsby-gdpr-google-analytics"
  >
    Nasza strona internetowa używa plików cookies. Zobacz{" "}
    <a href={PolitykaPrywatnosci} target="blank">
      politykę prywatności
    </a>
  </CookieConsent>
);

export default Cookies;
